import request from './index'

export const otp = (data) => {
  return request({
    method: 'POST',
    url: '/otp/send',
    data: data
  })
}

export const retry = (data) => {
  return request({
    method: 'POST',
    url: '/otp/retry',
    data: data
  })
}

export const verify = (data) => {
  return request({
    method: 'POST',
    url: '/otp/verify',
    data: data
  })
}

export const getUserInfo = () => {
  return request({
    method: 'GET',
    url: '/user/info'
  })
}

export const userLogin = (data) => {
  return request({
    method: 'POST',
    url: '/user/login',
    data: data
  })
}

export const updateProfile = (data) => {
  return request({
    method: 'POST',
    url: '/user/update',
    data: data
  })
}

export const forgotPassword = (data) => {
  return request({
    method: 'POST',
    url: '/user/forgot-password',
    data: data
  })
}

export const resetPassword = (data) => {
  return request({
    method: 'POST',
    url: '/user/reset-password',
    data: data
  })
}
